@import 'figure';

.pswp {
    z-index: 9999999;
    .pswp__caption__center {
        text-align: center
    }
}

.natural-gallery-js {
    position: relative;

    *,
    *::after,
    *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    iframe {
        position: absolute;
        width: 100%;
        height: 0;
        top: 0;
        left: 0;
        background-color: transparent;
        border: 0;
    }

    &.scrolling {
        &, & * {
            pointer-events: none !important;
        }
    }

    /**
    * Columns
    * ===================================================
    */

    .column {
        float: left;

        &:last-child {
            margin-right: 0 !important
        }

        .figure:last-child {
            margin-bottom: 0 !important;
        }
    }

    /**
    * Pictures
    * ===================================================
    */
    .natural-gallery-body {
        padding: 0;
        margin: 0;

        &.resizing {
            .figure, .column {
                transition: none;
                opacity: 0;
            }
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }

    }

    /**
    * Next
    * ===================================================
    */
    .natural-gallery-next {
        display: block;
        margin: 10px auto;
        text-align: center;
        height: 30px;

        svg {
            cursor: pointer;
            font-size: 16px;
            height: inherit;
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    /**
    * Animations
    * ===================================================
    */
    @keyframes loading {
        0% {
            transform: scale(0, 0);
            opacity: 0.3;
        }
        100% {
            transform: scale(1, 1);
            opacity: 0.05;
        }
    }

}
